import { combineReducers } from 'redux';

import { appBarReducer } from './appBarReducer';
import { Loader } from './loading_reducer';
import { Login, Session } from './login_reducer';
import { ClinicDetails } from './pt_reducer';
import { Reset } from './reset_password_reducer';
import { Snackbar } from './snackbar_reducer';

export default combineReducers({
  Session,
  ClinicDetails,
  Loader,
  Snackbar,
  appBar: appBarReducer,
  // purge
  Login,
  Reset,
});
