import {
  LDClient,
  LDContextCommon,
  LDMultiKindContext,
  useLDClient,
} from 'launchdarkly-react-client-sdk';
import { debounce } from 'lodash-es';
import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router';

import { SENDBIRD_APP_ID } from '@/old/config';
import { BRIDGE_SSO } from '@/old/config/app_routes';
import {
  bootIntercom,
  hideIntercom,
  updateIntercom,
} from '@/old/utils/thirdPartyAPIs/intercom';
import { Clinic, Company, UserSerializerSlim } from '@/schema';
import {
  selectDefaultClinic,
  useMetadataState,
  useMetadataSync,
} from '@/state/metadata-state';
import { identifySentryUser } from '@/utils/sentry';

import { useThemeSync } from '../state/theme-state';

const initDependencies = debounce(
  async (
    ldClient?: LDClient,
    user?: UserSerializerSlim,
    company?: Company,
    clinic?: Clinic,
  ) => {
    try {
      const userContext = (ldClient?.getContext() as LDMultiKindContext)
        .user as LDContextCommon | undefined;

      if (user && userContext?.key === `user-${user.id}`) {
        return;
      }

      // Intercom reboots if the user changes, usually at login/logout
      bootIntercom();

      if (user && clinic && company) {
        identifySentryUser(user);
        await ldClient?.identify({
          kind: 'multi',
          user: {
            key: `user-${user.id}`,
            name: `${user.firstName} ${user.lastName}`,
            email: user.email,
          },
          company: {
            key: `company-${company.id}`,
            name: company.name,
            domain: company.domain,
          },
          clinic: {
            key: `clinic-${clinic.uuid}`,
            name: clinic.name,
            domain: clinic.domain,
          },
        });
      } else {
        identifySentryUser();
        await ldClient?.identify({ anonymous: true });
      }
    } catch (error) {
      console.error(error);
    }
  },
  1e3,
);

export const RootLayout = () => {
  useThemeSync();
  useMetadataSync();

  const ldClient = useLDClient();
  const location = useLocation();

  const clinic = useMetadataState(selectDefaultClinic);
  const { company, user } = useMetadataState((state) => ({
    company: state.metadata?.company,
    user: state.metadata?.providerMetadata.user,
  }));

  useEffect(() => {
    initDependencies(ldClient, user, company, clinic);
  }, [clinic, company, ldClient, user]);

  useEffect(() => {
    // Important: You will need to call Intercom("update") with some new user data in
    // order to initiate a "ping" every time a URL changes. If no user data has
    // changed you can send the current time as the value for 'last_request_at' like this:
    updateIntercom();

    if (location.pathname.includes(BRIDGE_SSO)) {
      hideIntercom();
    }
  }, [location]);

  if (!SENDBIRD_APP_ID) {
    return <p>Set APP_ID in const.js</p>;
  }

  return <Outlet />;
};
