import { LucideIcon } from 'lucide-react';
import { Link } from 'react-router-dom';

import { NotificationDot } from './reusable-tooltips';
import { Button } from './ui/button';
import { Tooltip, TooltipContent, TooltipTrigger } from './ui/tooltip';

export interface SideNavTooltipLinkProps {
  to: string;
  Icon: LucideIcon;
  label: string;
  tooltip?: React.ReactNode;
  showNotificationDot?: boolean;
}

export const SideNavTooltipLink = ({
  to,
  Icon,
  label,
  tooltip,
  showNotificationDot,
}: SideNavTooltipLinkProps) => {
  return (
    <Link to={to} aria-label={`Go to ${label}`}>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            variant="ghost"
            size="icon"
            className="relative rounded-lg"
            aria-label={`${label} button`}
          >
            <Icon className="size-5 opacity-50" />
            {showNotificationDot && <NotificationDot isCorner />}
          </Button>
        </TooltipTrigger>

        <TooltipContent side="right" sideOffset={5}>
          {tooltip ? tooltip : label}
        </TooltipContent>
      </Tooltip>
    </Link>
  );
};
