import { useQueryClient } from '@tanstack/react-query';
import { Row, Table } from '@tanstack/react-table';
import { useState } from 'react';
import { toast } from 'sonner';

import { BillingInstance, OperationEnum } from '@/schema';
import { billingApi } from '@/state/api-instances';
import { BillingTab, useBillingState } from '@/state/billing-state';

import { ConfirmationDialog } from '../confirmation-dialog';
import { EllipsisMenuTrigger } from '../ellipsis-menu-trigger';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
} from '../ui/dropdown-menu';
import { BillingCancelAction } from './billing-cancel-action';
import { JustificationDialog } from './billing-justification-cell';

interface BillingActionCellProps {
  row: Row<BillingInstance>;
  table: Table<BillingInstance>;
}

export const BillingActionCell = ({ table, row }: BillingActionCellProps) => {
  const [open, setOpen] = useState(false);
  const tabType = useBillingState((state) => state.selectedTab);
  const queryClient = useQueryClient();

  if (tabType === BillingTab.Pending) {
    return 'This billing is still pending.';
  }

  const unmarkAsBilled = async () => {
    if (tabType !== BillingTab.Billed) {
      return;
    }

    await billingApi.billingBillinginstancesReconcileInBulkCreate({
      billingInstanceBulkBill: {
        uuids: [row.original.uuid],
        operation: 'UNRECONCILE',
      },
    });

    table.setPageIndex(() => 0);
    table
      .getFilteredSelectedRowModel()
      .rows.forEach((row) => row.toggleSelected(false));

    toast.success('Successfully unmarked as billed.');
    setOpen(false);

    await queryClient.resetQueries({
      queryKey: ['billingItems'],
    });
    await queryClient.resetQueries({
      queryKey: ['billingNotificationDot'],
    });
  };

  const undoCancellation = async () => {
    try {
      await billingApi.billingBillinginstancesCancelInBulkCreate({
        billingInstanceBulkCancel: {
          uuids: [row.original.uuid],
          operation: OperationEnum.Uncancel,
        },
      });

      table.setPageIndex(0);

      toast.success('Successfully undid the cancellation.');
      setOpen(false);

      await queryClient.resetQueries({
        queryKey: ['billingItems'],
      });
      await queryClient.resetQueries({
        queryKey: ['billingNotificationDot'],
      });
    } catch (error) {
      toast.error('Failed to undo cancellation.');
      console.error('Undo cancel billing error:', error);
    }
  };

  return (
    <DropdownMenu open={open} onOpenChange={setOpen}>
      <EllipsisMenuTrigger />

      <DropdownMenuContent>
        {tabType === BillingTab.Billed && (
          <>
            <DropdownMenuItem className="flex gap-2" onClick={unmarkAsBilled}>
              Unmark as Billed
            </DropdownMenuItem>

            <JustificationDialog
              isDropdownItem
              row={row}
              onSuccess={() => setOpen(false)}
            />
          </>
        )}

        {tabType === BillingTab.Ready && (
          <BillingCancelAction
            row={row}
            table={table}
            queryClient={queryClient}
            onSuccess={() => setOpen(false)}
          />
        )}

        {tabType === BillingTab.Cancelled && (
          <ConfirmationDialog
            title="Undo Cancellation?"
            description="This will move this card back to the 'ready to bill' tab."
            onConfirm={undoCancellation}
          >
            <DropdownMenuItem
              className="flex gap-2"
              onSelect={(e) => {
                e.preventDefault();
              }}
            >
              Undo Cancellation
            </DropdownMenuItem>
          </ConfirmationDialog>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
