import type { AuthUser } from '@arrowhealth/bridge-sdk';

/**
 * View Bridge SDK documentation:
 * https://arrowhealth.github.io/bridge-sdk/index.html
 */
let bridgeSDK: BridgeSDK;

/** Dynamically loads the Bridge API if running in an iframe. */
export async function initBridge(): Promise<boolean> {
  // Exit if not running in iframe, assume is not in bridge
  if (window.self === window.top) return false;

  try {
    bridgeSDK = await import('@arrowhealth/bridge-sdk');
    console.log('Bridge SDK Loaded', bridgeSDK);
    window.bridgeApi = bridgeSDK;
    return true;
  } catch (ex) {
    console.error('Could not load the Bridge SDK', ex);
  }

  return false;
}

/** Returns true if app is within the bridge iframe, and the Bridge SDK is loaded */
export function isInBridge(): boolean {
  return window.self !== window.top && !!bridgeSDK && bridgeSDK.inBridge;
}

/** Get the current authorized user from the EHR app using bridge. */
export async function getAuthUser(): Promise<AuthUser | null> {
  if (isInBridge()) {
    return await bridgeSDK.getAuthUser();
  }
  return null;
}

// /** Fires the handler anytime the current patient is changed. */
// export async function onPatientChange(handler: (patient: Patient) => void) {
//   if (isInBridge()) {
//     const patient = await bridgeSDK.getPatient();
//     if (patient) handler(convertBridgePatient(patient));
//     bridgeSDK.onPatientChanged((patient) => {
//       handler(convertBridgePatient(patient));
//     });
//   }
// }

// function convertBridgePatient(patient: BridgePatient): Patient {
//   return {
//     // id: patient.user.id,
//     firstName: patient.first,
//     lastName: patient.last,
//     // TODO: Retrieve phone/email from EHR or backend service
//     // phoneNumber: ...
//     // emailAddress: ...
//   };
// }
