import storage from 'redux-persist/lib/storage';

import {
  API_BASE_URL,
  BRIDGE_SSO_URI,
  LOGIN_URL,
  LOGOUT_URL,
} from '@/old/config/api_routes';
import { ASSN_LIST_PATIENT_VIEW, LOGIN } from '@/old/config/app_routes';
import { sendSnackbar } from '@/old/state/actions/snackbar_action';
import {
  CLOSE_FORGOT_PASSWORD,
  LOGIN_PENDING,
  LOGIN_SUCCESS,
  RESET_SESSION_DATA,
  SET_CURRENT_PATIENT_ID,
  STORE_SESSION_DATA,
} from '@/old/state/actions/types';
import {
  identifyIntercom,
  shutdownIntercom,
} from '@/old/utils/thirdPartyAPIs/intercom';
import SegmentIO from '@/old/utils/thirdPartyAPIs/segment';
import Sendbird from '@/old/utils/thirdPartyAPIs/sendbird';
import { setTileIconActive } from '@/old/views/pages/Auth/BridgeSSOTile';
import { PATIENT_LIST_URL } from '@/routes/app-routes';
import { ResponseError, sendWithRetry } from '@/utils/fetch-utils';
import { identifySentryUser } from '@/utils/sentry';

export const setLoginPending = (loginPending) => {
  return {
    type: LOGIN_PENDING,
    payload: { loginPending },
  };
};

export const setLoginSucces = (loginSucces) => {
  return {
    type: LOGIN_SUCCESS,
    payload: {
      loginSucces,
      loggedIn: true,
      username: loginSucces.username,
      user_type: loginSucces.user_type,
    },
  };
};

export const closeForgotPassword = () => {
  return {
    type: CLOSE_FORGOT_PASSWORD,
    payload: { open: false },
  };
};
export const resetSessionData = () => {
  return {
    type: RESET_SESSION_DATA,
    payload: {},
  };
};
export const storeSessionData = (data) => {
  return {
    type: STORE_SESSION_DATA,
    payload: { data },
  };
};

export const setCurrentPatient = (currentPatientId) => {
  return {
    type: SET_CURRENT_PATIENT_ID,
    payload: { currentPatientId },
  };
};
export const clearState = () => {
  return {
    type: 'CLEAR_STATE',
    payload: {},
  };
};

function initState(dispatch, data) {
  dispatch(storeSessionData(data));
  const { user } = data;
  if (user.user_type === 'patient') {
    dispatch(setCurrentPatient(user.id));
  }
}

function apiIdentifies(api, user, patient, pt) {
  identifyIntercom(user, patient, pt);
  SegmentIO.identify(user);
  identifySentryUser({
    id: user.id,
    email: user.email,
    fullName: user.full_name,
  });
}

function redirectUponLogin(navigate, userType) {
  if (userType === 'patient') {
    navigate(ASSN_LIST_PATIENT_VIEW);
  } else if (userType === 'pt') {
    navigate(PATIENT_LIST_URL);
  }
}

export const login = (navigate, username, password) => {
  return async (dispatch) => {
    dispatch(setLoginPending(true));

    try {
      const data = await sendWithRetry(`${API_BASE_URL}${LOGIN_URL}/`, {
        // use below when gql is ready
        // username: username.toLowerCase(), // can provide username or email
        username: username.toLowerCase(), // can provide username or email despite having to say username
        password,
      });

      initState(dispatch, data);

      const { user, patient, pt, api } = data;
      apiIdentifies(api, user, patient, pt);

      dispatch(setLoginSucces(user));
      redirectUponLogin(navigate, user.user_type);

      dispatch(sendSnackbar(`👋🏽 Welcome back ${username} 😀`, 'info'));
    } catch (error) {
      if (error instanceof ResponseError && error.response.status === 401) {
        dispatch(
          sendSnackbar(
            'Wrong username or password. Try again or click Forgot password to reset it.',
            'warning',
          ),
        );
        return error.response;
      }

      dispatch(
        sendSnackbar(
          'Network error, please try again later or contact support.',
          'warning',
        ),
      );

      return error;
    } finally {
      dispatch(setLoginPending(false));
    }
  };
};

export const loginBridgeSSO = (code) => {
  return async (dispatch) => {
    dispatch(setLoginPending(true));

    try {
      const data = await sendWithRetry(`${API_BASE_URL}${BRIDGE_SSO_URI}`, {
        code,
      });

      setTileIconActive();
      initState(dispatch, data);

      const { user, patient, pt, api } = data;
      apiIdentifies(api, user, patient, pt);

      dispatch(setLoginSucces(user));
      // set badge -- total unread messages

      const unreadMessagesCount = await Sendbird.unreadMessagesCount(user.id);
      const totalUnreadCount = Object.values(unreadMessagesCount).reduce(
        (a, b) => a + b,
        0,
      );

      window.bridgeApi?.setBadgeCount(totalUnreadCount);
    } catch (error) {
      if (error instanceof ResponseError && error.response.status === 400) {
        console.log('error', error.response);
      }

      return error;
    } finally {
      dispatch(setLoginPending(false));
    }
  };
};

export const logout = (wasInactive = false) => {
  return async (dispatch) => {
    try {
      shutdownIntercom();

      dispatch(resetSessionData()); // this will remove token and trigger redirection
      dispatch(clearState());
      clearLocalStorage();

      storage.removeItem('persist:root:PatientAssignments');

      await sendWithRetry(`${API_BASE_URL}${LOGOUT_URL}/`, {});

      if (wasInactive) {
        dispatch(sendSnackbar('Logged out due to inactivity', 'info'));
      } else {
        dispatch(sendSnackbar('Logout Successful', 'success'));
      }

      history.pushState({}, '', LOGIN);
      location.reload();
    } catch (error) {
      dispatch(sendSnackbar('Logout failed. Try again later.', 'error'));
      return error;
    }
  };
};

/** Keys not cleared during logout. */
const PERSISTED_KEYS = ['theme'];

/** Clear any non-persisted keys from local storage. */
function clearLocalStorage() {
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (!PERSISTED_KEYS.includes(key)) {
      localStorage.removeItem(key);
    }
  }
}
