import { useDispatch, useSelector } from 'react-redux';

import { closeSnackbar } from '@/old/state/actions/snackbar_action';
import Snackbar from '@/old/views/components/SnackBar/SnackBar';

const SnackBarContainer = () => {
  const dispatch = useDispatch();
  const { message, open, type } = useSelector((state) => state.Snackbar);

  const onHandleClose = () => {
    dispatch(closeSnackbar());
  };

  return (
    <Snackbar
      onHandleClose={onHandleClose}
      message={message}
      open={open}
      type={type}
    />
  );
};

export default SnackBarContainer;
