/* tslint:disable */

/* eslint-disable */

/**
 * SaRA Health API
 * The schema for SaRA Health backend
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';
import type { AccountOriginEnum } from './AccountOriginEnum';
import {
  AccountOriginEnumFromJSON,
  AccountOriginEnumFromJSONTyped,
  AccountOriginEnumToJSON,
} from './AccountOriginEnum';
import type { ClinicMembership } from './ClinicMembership';
import {
  ClinicMembershipFromJSON,
  ClinicMembershipFromJSONTyped,
  ClinicMembershipToJSON,
} from './ClinicMembership';
import type { UserTypeEnum } from './UserTypeEnum';
import {
  UserTypeEnumFromJSON,
  UserTypeEnumFromJSONTyped,
  UserTypeEnumToJSON,
} from './UserTypeEnum';

/**
 *
 * @export
 * @interface User
 */
export interface User {
  /**
   *
   * @type {number}
   * @memberof User
   */
  readonly id: number;
  /**
   *
   * @type {string}
   * @memberof User
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  lastName?: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  readonly fullName: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  username?: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  phone?: string;
  /**
   *
   * @type {AccountOriginEnum}
   * @memberof User
   */
  accountOrigin: AccountOriginEnum;
  /**
   *
   * @type {number}
   * @memberof User
   */
  createdBy: number | null;
  /**
   *
   * @type {string}
   * @memberof User
   */
  password?: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  defaultClinic: string;
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  isDummy?: boolean;
  /**
   *
   * @type {string}
   * @memberof User
   */
  language: string;
  /**
   *
   * @type {UserTypeEnum}
   * @memberof User
   */
  userType: UserTypeEnum;
  /**
   *
   * @type {number}
   * @memberof User
   */
  ptUsername?: number;
  /**
   *
   * @type {Date}
   * @memberof User
   */
  smsOptOutDate?: Date;
  /**
   *
   * @type {Date}
   * @memberof User
   */
  smsUnreachableDate?: Date;
  /**
   *
   * @type {Array<ClinicMembership>}
   * @memberof User
   */
  readonly clinicMemberships: Array<ClinicMembership>;
  /**
   * The email of the primary provider.
   * @type {string}
   * @memberof User
   */
  primaryProvider?: string;
}

/**
 * Check if a given object implements the User interface.
 */
export function instanceOfUser(value: object): boolean {
  if (!('id' in value)) return false;
  if (!('fullName' in value)) return false;
  if (!('accountOrigin' in value)) return false;
  if (!('createdBy' in value)) return false;
  if (!('defaultClinic' in value)) return false;
  if (!('language' in value)) return false;
  if (!('userType' in value)) return false;
  if (!('clinicMemberships' in value)) return false;
  return true;
}

export function UserFromJSON(json: any): User {
  return UserFromJSONTyped(json, false);
}

export function UserFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): User {
  if (json == null) {
    return json;
  }
  return {
    id: json['id'],
    firstName: json['first_name'] == null ? undefined : json['first_name'],
    lastName: json['last_name'] == null ? undefined : json['last_name'],
    fullName: json['full_name'],
    username: json['username'] == null ? undefined : json['username'],
    email: json['email'] == null ? undefined : json['email'],
    phone: json['phone'] == null ? undefined : json['phone'],
    accountOrigin: AccountOriginEnumFromJSON(json['account_origin']),
    createdBy: json['created_by'],
    password: json['password'] == null ? undefined : json['password'],
    defaultClinic: json['default_clinic'],
    isDummy: json['is_dummy'] == null ? undefined : json['is_dummy'],
    language: json['language'],
    userType: UserTypeEnumFromJSON(json['user_type']),
    ptUsername: json['pt_username'] == null ? undefined : json['pt_username'],
    smsOptOutDate:
      json['sms_opt_out_date'] == null
        ? undefined
        : new Date(json['sms_opt_out_date']),
    smsUnreachableDate:
      json['sms_unreachable_date'] == null
        ? undefined
        : new Date(json['sms_unreachable_date']),
    clinicMemberships: (json['clinic_memberships'] as Array<any>).map(
      ClinicMembershipFromJSON,
    ),
    primaryProvider:
      json['primary_provider'] == null ? undefined : json['primary_provider'],
  };
}

export function UserToJSON(value?: User | null): any {
  if (value == null) {
    return value;
  }
  return {
    first_name: value['firstName'],
    last_name: value['lastName'],
    username: value['username'],
    email: value['email'],
    phone: value['phone'],
    account_origin: AccountOriginEnumToJSON(value['accountOrigin']),
    created_by: value['createdBy'],
    password: value['password'],
    default_clinic: value['defaultClinic'],
    is_dummy: value['isDummy'],
    language: value['language'],
    user_type: UserTypeEnumToJSON(value['userType']),
    pt_username: value['ptUsername'],
    sms_opt_out_date:
      value['smsOptOutDate'] == null
        ? undefined
        : (value['smsOptOutDate'] as any).toISOString(),
    sms_unreachable_date:
      value['smsUnreachableDate'] == null
        ? undefined
        : (value['smsUnreachableDate'] as any).toISOString(),
    primary_provider: value['primaryProvider'],
  };
}
