/* tslint:disable */

/* eslint-disable */

/**
 * SaRA Health API
 * The schema for SaRA Health backend
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';
import type { ProviderPatientRelationship } from './ProviderPatientRelationship';
import {
  ProviderPatientRelationshipFromJSON,
  ProviderPatientRelationshipFromJSONTyped,
  ProviderPatientRelationshipToJSON,
} from './ProviderPatientRelationship';
import type { ProviderSerializerSlim } from './ProviderSerializerSlim';
import {
  ProviderSerializerSlimFromJSON,
  ProviderSerializerSlimFromJSONTyped,
  ProviderSerializerSlimToJSON,
} from './ProviderSerializerSlim';
import type { RTMCycle } from './RTMCycle';
import {
  RTMCycleFromJSON,
  RTMCycleFromJSONTyped,
  RTMCycleToJSON,
} from './RTMCycle';
import type { RTMEnrollment } from './RTMEnrollment';
import {
  RTMEnrollmentFromJSON,
  RTMEnrollmentFromJSONTyped,
  RTMEnrollmentToJSON,
} from './RTMEnrollment';
import type { User } from './User';
import { UserFromJSON, UserFromJSONTyped, UserToJSON } from './User';

/**
 *
 * @export
 * @interface Patient
 */
export interface Patient {
  /**
   *
   * @type {User}
   * @memberof Patient
   */
  readonly user: User;
  /**
   *
   * @type {number}
   * @memberof Patient
   */
  readonly currentIntlogMins: number;
  /**
   *
   * @type {string}
   * @memberof Patient
   */
  readonly sbChannelUrl: string | null;
  /**
   *
   * @type {Array<ProviderPatientRelationship>}
   * @memberof Patient
   */
  readonly providerRelationships: Array<ProviderPatientRelationship>;
  /**
   *
   * @type {ProviderSerializerSlim}
   * @memberof Patient
   */
  readonly primaryProvider: ProviderSerializerSlim;
  /**
   *
   * @type {RTMCycle}
   * @memberof Patient
   */
  readonly currentRtmcycle: RTMCycle | null;
  /**
   *
   * @type {RTMEnrollment}
   * @memberof Patient
   */
  readonly latestRtmenrollment: RTMEnrollment | null;
  /**
   *
   * @type {boolean}
   * @memberof Patient
   */
  readonly isCurrentlyEnrolled: boolean;
}

/**
 * Check if a given object implements the Patient interface.
 */
export function instanceOfPatient(value: object): boolean {
  if (!('user' in value)) return false;
  if (!('currentIntlogMins' in value)) return false;
  if (!('sbChannelUrl' in value)) return false;
  if (!('providerRelationships' in value)) return false;
  if (!('primaryProvider' in value)) return false;
  if (!('currentRtmcycle' in value)) return false;
  if (!('latestRtmenrollment' in value)) return false;
  if (!('isCurrentlyEnrolled' in value)) return false;
  return true;
}

export function PatientFromJSON(json: any): Patient {
  return PatientFromJSONTyped(json, false);
}

export function PatientFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): Patient {
  if (json == null) {
    return json;
  }
  return {
    user: UserFromJSON(json['user']),
    currentIntlogMins: json['current_intlog_mins'],
    sbChannelUrl: json['sb_channel_url'],
    providerRelationships: (json['provider_relationships'] as Array<any>).map(
      ProviderPatientRelationshipFromJSON,
    ),
    primaryProvider: ProviderSerializerSlimFromJSON(json['primary_provider']),
    currentRtmcycle: RTMCycleFromJSON(json['current_rtmcycle']),
    latestRtmenrollment: RTMEnrollmentFromJSON(json['latest_rtmenrollment']),
    isCurrentlyEnrolled: json['is_currently_enrolled'],
  };
}

export function PatientToJSON(value?: Patient | null): any {
  if (value == null) {
    return value;
  }
  return {};
}
