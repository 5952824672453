import { toast } from 'sonner';

import { ResponseError } from '@/schema';

/**
 *
 * @export
 * @interface ParseError
 */
export interface ParseError {
  /**
   *
   * @type {string}
   * @memberof ParseError
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof ParseError
   */
  detail: string;
  /**
   *
   * @type {string}
   * @memberof ParseError
   */
  attr: string | null;
}

/**
 *
 * @export
 * @interface ParseErrorResponse
 */
export interface ResponseErrorPayload {
  /**
   *
   * @type {string}
   * @memberof ParseErrorResponse
   */
  type: string;
  /**
   *
   * @type {Array<ParseError>}
   * @memberof ParseErrorResponse
   */
  errors: Array<ParseError>;
}

export function getErrorDetail(payload: ResponseErrorPayload): string {
  return payload?.errors?.[0]?.detail;
}

export async function handleResponseError(
  error: unknown,
  errorToastLabel = 'Request failed',
): Promise<boolean> {
  console.error('Request error:', error);

  if (error instanceof ResponseError && error.response.status === 400) {
    toast.error(errorToastLabel, {
      description:
        getErrorDetail(await error.response.json()) ??
        'If the problem persists please contact support',
    });
    return true;
  }

  return false;
}
