import { SENDBIRD_API_TOKEN, SENDBIRD_APP_ID } from '@/old/config';
import { fetchWithRetry, sendWithRetry } from '@/utils/fetch-utils';

const host = `https://api-${SENDBIRD_APP_ID}.sendbird.com`;
const config = {
  headers: {
    'Api-Token': `${SENDBIRD_API_TOKEN}`,
  },
};

export const inviteProvider = async (userId, channelUrl) => {
  try {
    await sendWithRetry(
      `${host}/v3/group_channels/${channelUrl}/invite`,
      {
        channel_url: channelUrl,
        user_ids: [String(userId)],
      },
      config,
    );
    console.log('Invitation sent successfully');
  } catch (error) {
    console.log('Error sending invitation:', error);
  }
};

const Sendbird = {
  unreadMessagesCount: async (userId) => {
    // todo: we only show the first 100 unread channels, we should show all
    const data = await fetchWithRetry(
      `${host}/v3/users/${userId}/my_group_channels?limit=100&unread_filter=unread_message`,
      config,
    );

    const unreadCounts = data.channels.reduce((unreadCounts, channel) => {
      unreadCounts[channel.channel_url] = channel.unread_message_count;
      return unreadCounts;
    }, {});

    return unreadCounts;
  },
};
export default Sendbird;
