export const SNACKBAR = 'SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';

export const sendSnackbar = (message, type) => ({
  type: SNACKBAR,
  payload: {
    message,
    type,
    open: true,
  },
});

export const closeSnackbar = () => ({
  type: CLOSE_SNACKBAR,
  payload: { open: false },
});
