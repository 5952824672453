import { CLOSE_SNACKBAR, SNACKBAR } from '@/old/state/actions/snackbar_action';

const initialState = {
  message: '',
  type: '',
  open: false,
};
export const Snackbar = (state = initialState, action) => {
  switch (action.type) {
    case SNACKBAR:
      return action.payload;

    case CLOSE_SNACKBAR:
      return { ...state, ...action.payload };

    default:
      return state;
  }
};
