/* tslint:disable */

/* eslint-disable */

/**
 * SaRA Health API
 * The schema for SaRA Health backend
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import type {
  TractorscopeUrlReq,
  TractorscopeUrlResponse,
} from '../models/index';
import {
  TractorscopeUrlReqFromJSON,
  TractorscopeUrlReqToJSON,
  TractorscopeUrlResponseFromJSON,
  TractorscopeUrlResponseToJSON,
} from '../models/index';
import * as runtime from '../runtime';

export interface VendorsWebhooksGenerateTractorscopeUrlsCreateRequest {
  tractorscopeUrlReq: TractorscopeUrlReq;
}

/**
 *
 */
export class VendorsApi extends runtime.BaseAPI {
  /**
   * Will take in a customer.io event webhook and handles email and sms events  email:     subscribe, unsubscribe - nothing sms     sent - create a message object also send a message to sendbird     bounced, failed - note down in user object
   */
  async vendorsWebhooksCioSmsOutgoingCreateRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/vendors/webhooks/cio-sms-outgoing`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Will take in a customer.io event webhook and handles email and sms events  email:     subscribe, unsubscribe - nothing sms     sent - create a message object also send a message to sendbird     bounced, failed - note down in user object
   */
  async vendorsWebhooksCioSmsOutgoingCreate(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.vendorsWebhooksCioSmsOutgoingCreateRaw(initOverrides);
  }

  /**
   */
  async vendorsWebhooksGenerateTractorscopeUrlsCreateRaw(
    requestParameters: VendorsWebhooksGenerateTractorscopeUrlsCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<TractorscopeUrlResponse>> {
    if (requestParameters['tractorscopeUrlReq'] == null) {
      throw new runtime.RequiredError(
        'tractorscopeUrlReq',
        'Required parameter "tractorscopeUrlReq" was null or undefined when calling vendorsWebhooksGenerateTractorscopeUrlsCreate().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/vendors/webhooks/generate-tractorscope-urls/`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: TractorscopeUrlReqToJSON(requestParameters['tractorscopeUrlReq']),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      TractorscopeUrlResponseFromJSON(jsonValue),
    );
  }

  /**
   */
  async vendorsWebhooksGenerateTractorscopeUrlsCreate(
    requestParameters: VendorsWebhooksGenerateTractorscopeUrlsCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<TractorscopeUrlResponse> {
    const response =
      await this.vendorsWebhooksGenerateTractorscopeUrlsCreateRaw(
        requestParameters,
        initOverrides,
      );
    return await response.value();
  }

  /**
   */
  async vendorsWebhooksSendbirdSmsOutgoingCreateRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/vendors/webhooks/sendbird-sms-outgoing`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async vendorsWebhooksSendbirdSmsOutgoingCreate(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.vendorsWebhooksSendbirdSmsOutgoingCreateRaw(initOverrides);
  }

  /**
   */
  async vendorsWebhooksTwilioSmsIncomingCreateRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/vendors/webhooks/twilio-sms-incoming`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async vendorsWebhooksTwilioSmsIncomingCreate(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.vendorsWebhooksTwilioSmsIncomingCreateRaw(initOverrides);
  }
}
