import { LanguageEnum } from '@/schema';

const language = navigator.language;
const displayNames = new Intl.DisplayNames([language], { type: 'language' });

export function getLanguageLabel(locale: LanguageEnum): string {
  return displayNames.of(locale) ?? 'Unknown language';
}

const pluralRules = new Intl.PluralRules(language);

export function pluralize(
  count: number,
  singular: string,
  plural: string,
): string {
  const grammaticalNumber = pluralRules.select(count);
  return grammaticalNumber === 'one' ? singular : plural;
}
