import { Alert, Snackbar } from '@mui/material';
import { createContext, useCallback, useContext, useState } from 'react';

type Severity = 'success' | 'error' | 'warning' | 'info';

type SnackbarContextType = {
  displaySnackbar: (message: string, severity: Severity) => void;
};

const SnackbarContext = createContext<SnackbarContextType | null>(null);

export type SnackbarProviderProps = {
  children: React.ReactNode;
};

export const SnackbarProvider = ({ children }: SnackbarProviderProps) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState<Severity>('success');

  const handleClose: any = (_event: any, reason: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const displaySnackbar = useCallback((message: string, severity: Severity) => {
    setOpen(true);
    setMessage(message);
    setSeverity(severity);
  }, []);

  const snackbar = (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert
        onClose={handleClose}
        severity={severity}
        sx={{ width: '320px' }}
        variant="filled"
      >
        {message}
      </Alert>
    </Snackbar>
  );

  return (
    <SnackbarContext.Provider value={{ displaySnackbar }}>
      {children}
      {snackbar}
    </SnackbarContext.Provider>
  );
};
