// auth
export const LOGIN = '/';
export const LOGOUT = '/logout';
export const REGISTRATION = '/registration/';
export const FORGOT_PASSWORD = '/forgot/password/';
export const RESET_PASSWORD_URI = '/password-reset/:token';
export const VERIFY_EMAIL = '/email-verify/:id/';

// Arrowhealth Bridge
export const BRIDGE_APP = '/bridge-app';
export const BRIDGE_SSO = '/bridge-sso';

// Exercise
export const ASSN_LIST_PATIENT_VIEW = '/patient/routine/';

export const SUPPORT = '/support';
export const INTERACTION_LOG = '/logs';
