import { useEffect, useRef } from 'react';
import { Outlet } from 'react-router';

import SegmentIO from '@/old/utils/thirdPartyAPIs/segment';
import SnackBarContainer from '@/old/views/containers/SnackBarContainer';

const PageWrapperBase = ({
  isSubLayout = false,
}: {
  isSubLayout?: boolean;
}) => {
  useEffect(() => {
    SegmentIO.page(); // Segment pageview call
  }, []);

  const content = useRef(
    <>
      <SnackBarContainer />
      <Outlet />
    </>,
  );

  return (
    <>
      {isSubLayout ? (
        content.current
      ) : (
        <div className="h-full overflow-y-auto">{content.current}</div>
      )}
    </>
  );
};

export default PageWrapperBase;
