import { debounce } from 'lodash-es';
import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

import { PtPatientListListRequest } from '@/schema';

export type SearchType = PtPatientListListRequest['search'];

export interface FilterState {
  debounceSearch?: SearchType;
  providerId: null | string;
  search?: SearchType;
  setDebounceSearch: (search: SearchType) => void;
  setProviderId: (providerId: 'null' | string) => void;
  setSearch: (search: SearchType) => void;
}

export const useFilterState = create<FilterState>()(
  devtools(
    persist(
      (set) =>
        ({
          providerId: null,
          setSearch: (search) => {
            set({ search });
            debounceSearch(search);
          },
          setDebounceSearch: (debounceSearch) => set({ debounceSearch }),
          setProviderId: (providerId) => set({ providerId }),
        }) as FilterState,
      { name: 'filter-state' },
    ),
    { name: 'filter-state' },
  ),
);

const setDebounceSearch = useFilterState.getState().setDebounceSearch;

const debounceSearch = debounce((search) => {
  setDebounceSearch(search);
}, 500);
