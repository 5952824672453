import { TriangleAlertIcon } from 'lucide-react';
import { useRouteError } from 'react-router';

import { Button } from '@/components/ui/button';

export const ErrorPage = () => {
  const error = useRouteError() as Error;

  const isLazyError = error?.message.includes('dynamically imported module');

  return (
    <div className="flex h-full w-full flex-col content-center items-center justify-center gap-4 text-center">
      <TriangleAlertIcon size={120} />

      <h1 className="scroll-m-20 text-4xl font-extrabold tracking-tight lg:text-5xl">
        {isLazyError ? 'App updates in progress' : 'An error occurred'}
      </h1>

      <p className="leading-7">
        {isLazyError
          ? 'Please wait a minute and refresh the page.'
          : 'Please reload the page, if the problem persist contact support'}
      </p>

      <Button onClick={() => window.location.reload()}>
        Click here to reload the page
      </Button>
    </div>
  );
};
