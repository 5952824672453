import { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { loginBridgeSSO } from '@/old/state/actions/Login/login_actions';

export const setTileIconActive = () => {
  const element = document.getElementById('bridge-tile-icon');
  if (element) {
    element.style.filter = 'grayscale(0%)'; // Modify the desired style properties here
  }
};

function BridgeSSO({ sso }) {
  // const { loginWithRedirect } = useAuth0();
  const [code, setCode] = useState<string>('');
  const [authUser, setAuthUser] = useState({});
  const [authStatus, setAuthStatus] = useState({});
  const [patient, setPatient] = useState({});

  useEffect(() => {
    window.bridgeApi?.getAuthUser().then((v) => setAuthUser(v));
    window.bridgeApi?.getAuthStatus().then((v) => setAuthStatus(v));
    window.bridgeApi?.getPatient().then((v) => setPatient(v));
    window.bridgeApi?.onPatientChanged((v) => setPatient(v));
  }, []);

  function getOIDCCodeFromRedirect() {
    // auth0 passive authentication
    // https://auth0.com/docs/api/authentication#database-ad-ldap-passive-
    const domain = 'https://sso.bridge.arrowhealth.io';
    const client_id = 'WFItavVLCYSlSdr6cai9spS89Mh2KvOz';
    const scope = 'openid profile email';
    const redirect_uri = `${window.location.protocol}//${window.location.host}/bridge-sso`;
    // let response_mode = 'query'
    // let response_type = 'code'

    const authorizeURL =
      domain +
      '/authorize?client_id=' +
      encodeURIComponent(client_id) +
      '&scope=' +
      encodeURIComponent(scope) +
      '&redirect_uri=' +
      encodeURIComponent(redirect_uri) +
      '&response_type=code';

    window.location.replace(authorizeURL);
  }

  useEffect(() => {
    async function fetchData() {
      // wait until the bridge user session has been established
      // let authUser1 = await bridge.getAuthUser();
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const code = urlParams.get('code');

      if (code) {
        // https://auth0.com/docs/api/authentication?shell#authorization-code-flow46
        setCode(code!);
        // call sara endpoint to get back login payload
        sso(code);
      } else {
        // await loginWithRedirect();
        getOIDCCodeFromRedirect();
      }
    }
    fetchData();
    // // if the user is not logged in, then redirect to auth0 and get the code to pass to bridge and get the token
    // if (store.getState().Session.token === null) {
    //   fetchData();
    // } else {
    //   // if the user is logged in, then redirect to home page
    //   window.location.replace('/');
    // }
  }, []);

  return (
    <div
      className="bridge-tile"
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 9999,
        backgroundColor: 'rgb(32, 33, 36)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <img
        id="bridge-tile-icon"
        src="/favicon.ico"
        style={{ filter: 'grayscale(100%)', width: 24, height: 24 }}
        alt="Favicon"
      />
    </div>
  );
}

export const mapDispatchToProps = (dispatch) => {
  return {
    sso: (code) => dispatch(loginBridgeSSO(code)),
  };
};

export default connect(null, mapDispatchToProps)(BridgeSSO);
